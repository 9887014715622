<template>
  <div class="personal-info">
    <!-- 形象 -->
    <div class="personal-info-item col-center">
      <div
        style="width: 100%;"
        class="row-between"
      >
        <p class="personal-info-title">形象</p>
        <img
          class="personal-info-img"
          :src="personalInfo.avatar"
        />
      </div>

    </div>
    <!-- UID -->
    <div class="personal-info-item col-center">
      <div
        style="width: 100%;"
        class="row-between"
      >
        <p class="personal-info-title">UID</p>
        <p class="personal-info-content">{{ personalInfo.uid }}</p>
      </div>

    </div>
    <!-- 昵称 -->
    <div class="personal-info-item col-center">
      <div
        style="width: 100%;"
        class="row-between"
      >
        <p class="personal-info-title">修改手机号</p>
        <p
          class="personal-info-content"
          style="width: 70%;display: flex; flex-direction: row; justify-content: flex-end; align-items: center;"
        >
          <!-- @click="goModifyPhone" -->
          {{ personalInfo.phone }}
          <!-- <img
            style="margin:0 0 0 0.75rem;"
            class="next-img"
            src="../assets/icon/next.png"
          /> -->
        </p>
      </div>

    </div>
    <!-- 链账户 -->
    <div class="personal-info-item col-center">
      <div
        style="width: 100%;"
        class="row-between"
      >
        <p class="personal-info-title">链账户</p>
        <p class="personal-info-content">{{ personalInfo.chainAccount }}</p>
      </div>

    </div>
    <!-- 昵称 -->
    <div class="personal-info-item col-center">
      <div
        style="width: 100%;"
        class="row-between"
      >
        <p class="personal-info-title">昵称</p>
        <p
          v-if="modifyName"
          class="personal-info-content"
          style="width: 70%;display: flex; flex-direction: row; justify-content: flex-end; align-items: center;"
          @click="modifyVisible = true;personalName = personalInfo.nickname"
        >
          {{ personalInfo.nickname }}
          <img
            style="margin:0 0 0 0.75rem;"
            class="next-img"
            src="../assets/icon/next.png"
          />
        </p>
        <p
          v-else
          class="personal-info-content"
          style="width: 70%;display: flex; flex-direction: row; justify-content: flex-end; align-items: center;"
        >
          {{ personalInfo.nickname }}
        </p>
      </div>

    </div>
    <!-- 实名认证 -->
    <div
      v-if="!personalInfo.hasRealname"
      class="personal-info-item col-center"
    >
      <div
        style="width: 100%;"
        class="row-between"
      >
        <p class="personal-info-title">实名认证</p>
        <p
          class="personal-info-content"
          style="width: 70%;display: flex; flex-direction: row; justify-content: flex-end; align-items: center;"
          @click="goCertification"
        >
          去认证
          <img
            style="margin:0 0 0 0.75rem;"
            class="next-img"
            src="../assets/icon/next.png"
          />
        </p>
      </div>
    </div>
    <!-- 已认证 -->
    <div
      v-if="personalInfo.hasRealname"
      class="personal-info-item col-center"
    >
      <div
        style="width: 100%;"
        class="row-between"
      >
        <p class="personal-info-title">姓名</p>
        <p class="personal-info-content">{{ personalInfo.realName}}</p>
      </div>

    </div>
    <div
      v-if="personalInfo.hasRealname"
      class="personal-info-item col-center"
    >
      <div
        style="width: 100%;"
        class="row-between"
      >
        <p class="personal-info-title">身份证号</p>
        <p class="personal-info-content">{{ personalInfo.cardNo }}</p>
      </div>
    </div>
    <el-dialog
      title="修改昵称"
      :visible.sync="modifyVisible"
      @confirm="modifyName"
      center
    >
      <van-field
        autocomplete="off"
        class="personal-name"
        v-model="personalName"
        :right-icon="require('../assets/icon/close-icon.png')"
        @click-right-icon="clearPersonalName"
      />
      <span
        slot="footer"
        class="dialog-footer row-center"
      >
        <el-button @click="modifyVisible = false">取 消</el-button>
        <div
          @click="ModifyName"
          class="confirm-button row-center"
        >确认</div>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="browerOpenVisible"
      @confirm="browerOpenVisible = false"
      class="donation-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-title"
      >请前往手机端完成实名认证</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="browerOpenVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import Vue from 'vue';
import { Field } from 'vant';
import Cookies from 'js-cookie'

Vue.use(Field);

import { Button,Dialog } from 'element-ui';
Vue.use(Button)
Vue.use(Dialog);
export default {
  data () {
    return {
      modifyName: true,
      browerOpenVisible: false,
      modifyVisible: false,
      personalName: '',
      personalInfo: {
        avatar: ''
      }
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.getMyInfo() // 获取个人资料
    this.modifyName = Cookies.get('Device') == 'pico' ? false : true
  },
  methods: {
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.personalInfo = result.data.data
            this.personalName = this.personalInfo.nickname
            if (result.data.data.hasRealname) {
              this.$store.commit('HIDE_ISAUTH')
            } else {
              this.$store.commit('SHOW_ISAUTH')
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          setTimeout(() => {
            this.$store.commit('HIDE_APPLOADING')
          },1000)

        })
    },
    goCertification () {
      if (Cookies.get('Device') == 'pico') {
        this.browerOpenVisible = true
      } else {
        this.$router.push('/certification')
      }

    },
    goModifyPhone () {
      this.$router.push('/modifyPhone')
    },
    clearPersonalName () {
      this.personalName = ''
    },
    ModifyName () {
      if (!this.personalName || this.personalName.length > 16) {
        this.$toast.fail('请填写16个字以内的昵称')
      } else {
        api
          .post('user/edit',{ nickname: this.personalName })
          .then(result => {
            if (result.data.success) {
              this.personalInfo.nickname = this.personalName
              Cookies.set('nickname',this.personalName,{ expires: 7 })
              this.modifyVisible = false
              this.$toast.success('修改成功')

            } else {
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
          })
      }
    }
  },
}
</script>
<style lang="less" scoped>
.personal-info {
  margin-bottom: 3.125rem;
  margin-top: 3.875rem;
  height: calc(100vh - 7rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .personal-info-item {
    width: 90%;
    height: 50px;
    background: #262728;
    border-radius: 8px;
    margin: 0.75rem 5% 0 5%;
    .personal-info-content {
      width: 50%;
      word-break: break-all;
      font-size: 0.875rem;
      font-weight: 500;
      color: #f0f0f0;
      line-height: 1.25rem;
      text-align: right;
      margin-right: 1rem;
    }
    .personal-info-img {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      margin-right: 1rem;
    }

    .personal-info-title {
      font-size: 0.875rem;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #f0f0f0;
      line-height: 1.1875rem;
      margin-left: 1.125rem;
    }
  }
}
</style>